import {
  components,
} from '@/types/openapi.ts';

export enum EAppGroupSemantic {
  OPERATION = 'Operation',
  DATA = 'Data',
  MAINTENANCE = 'Maintenance',
  PROCESS = 'Process',
  CONFIGURATION = 'Configuration',
}

export const appGroupSemantic: Record<components['schemas']['AppGroupSemantic'], keyof typeof EAppGroupSemantic> = {
  Operation: 'OPERATION',
  Data: 'DATA',
  Maintenance: 'MAINTENANCE',
  Process: 'PROCESS',
  Configuration: 'CONFIGURATION',
};

export enum EAppIdMap {
  PRODUCTION_LOGS = 1,
  MAINTENANCE_DASHBOARD = 2,
  ASSET_MANAGEMENT = 3,
  UPC_PRO = 4,
  DEPRECATED_NITREX_LIVE = 5,
  USER_MANAGEMENT = 6,
  DEPRECATED_USER_SETTINGS = 7,
  PT9800_CONFIGURATION = 8,
  SIMULATOR = 9,
  DEPRECATED_RECIPE_NPM = 10,
  REMOTE_CONTROL = 11,
  DEPRECATED_PT_9800_CLOUD = 12,
  LIVE = 13,
  RECIPE = 14,
  DEPRECATED_PT9800_RECIPES = 15,
  SHOP_LAYOUT = 16,
  RECIPE_ON_DEMAND = 17,
  MATERIALS = 18,
  PERFORMANCE_DASHBOARD = 19,
  ANOMALY_DETECTION = 20,
  DEPRECATED_MODEL_PROCESS = 21,
  PRODUCTION_STATISTICS = 22,
  HISTORY_AND_TRENDS = 23,
  ALARM_STATISTICS = 24,
  ML_PROTOTYPING = 25,
  GRAFANA = 26,
  CERTIFICATION = 27,
  MAINTENANCE_CALENDAR = 28,
  PARTS = 29,
  CUSTOMER = 30,
  OPERATION_CALENDAR = 31,
  TENANT_SETTINGS = 32,
  MODEL_BUILDER = 33,
}

export const appIdMap: Record<components['schemas']['AppId-Output'], keyof typeof EAppIdMap> = {
  1: 'PRODUCTION_LOGS',
  2: 'MAINTENANCE_DASHBOARD',
  3: 'ASSET_MANAGEMENT',
  4: 'UPC_PRO',
  5: 'DEPRECATED_NITREX_LIVE',
  6: 'USER_MANAGEMENT',
  7: 'DEPRECATED_USER_SETTINGS',
  8: 'PT9800_CONFIGURATION',
  9: 'SIMULATOR',
  10: 'DEPRECATED_RECIPE_NPM',
  11: 'REMOTE_CONTROL',
  12: 'DEPRECATED_PT_9800_CLOUD',
  13: 'LIVE',
  14: 'RECIPE',
  15: 'DEPRECATED_PT9800_RECIPES',
  16: 'SHOP_LAYOUT',
  17: 'RECIPE_ON_DEMAND',
  18: 'MATERIALS',
  19: 'PERFORMANCE_DASHBOARD',
  20: 'ANOMALY_DETECTION',
  21: 'DEPRECATED_MODEL_PROCESS',
  22: 'PRODUCTION_STATISTICS',
  23: 'HISTORY_AND_TRENDS',
  24: 'ALARM_STATISTICS',
  25: 'ML_PROTOTYPING',
  26: 'GRAFANA',
  27: 'CERTIFICATION',
  28: 'MAINTENANCE_CALENDAR',
  29: 'PARTS',
  30: 'CUSTOMER',
  31: 'OPERATION_CALENDAR',
  32: 'TENANT_SETTINGS',
  33: 'MODEL_BUILDER',
};

export enum EAppGroupCommercial {
  QMULUS_ESSENTIAL = 'QmulusEssential',
  QMULUS_LITE = 'QmulusLite',
  SETTINGS = 'Settings',
  QMULUS_EXPERT = 'QmulusExpert',
  SIMULATORS = 'Simulators',
  QMULUS_OPERATIONS = 'QmulusOperations',
  QMULUS_PERFORMANCE = 'QmulusPerformance',
  QMULUS_AI = 'QmulusAi',
  QMULUS_ANALYTICS = 'QmulusAnalytics',
  QMULUS_AUDIT = 'QmulusAudit',
  QMULUS_QUALITY = 'QmulusQuality',
  QMULUS_PLANNER = 'QmulusPlanner',
  QMULUS_TOOLING_AND_GAUGE_MANAGER = 'QmulusToolingAndGaugeManager',
  QMULUS_AUTOMATION = 'QmulusAutomation',
}

export const appGroupCommercial: Record<components['schemas']['AppGroupCommercial'], keyof typeof EAppGroupCommercial> = {
  QmulusEssential: 'QMULUS_ESSENTIAL',
  QmulusLite: 'QMULUS_LITE',
  Settings: 'SETTINGS',
  QmulusExpert: 'QMULUS_EXPERT',
  Simulators: 'SIMULATORS',
  QmulusOperations: 'QMULUS_OPERATIONS',
  QmulusPerformance: 'QMULUS_PERFORMANCE',
  QmulusAi: 'QMULUS_AI',
  QmulusAnalytics: 'QMULUS_ANALYTICS',
  QmulusAudit: 'QMULUS_AUDIT',
  QmulusQuality: 'QMULUS_QUALITY',
  QmulusPlanner: 'QMULUS_PLANNER',
  QmulusToolingAndGaugeManager: 'QMULUS_TOOLING_AND_GAUGE_MANAGER',
  QmulusAutomation: 'QMULUS_AUTOMATION',
};

export type TApp = (components['schemas']['App'] & {
  displayName: string,
});

export type TAppMap = Record <components['schemas']['AppGroupSemantic'], TApp[]>;
